$sgbs-font-path: '~@sg-bootstrap/core/fonts';
$sgbs-icons-font-path: '~@sg-bootstrap/icons/fonts/';
$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome.scss';
@import '@sg-bootstrap/icons/dist/index.css';
@import '@sg-bootstrap/core/dist/css/sg-bootstrap-standard.css';
@import 'ag-grid-community/dist/styles/ag-grid.min.css';
@import '@sg-bootstrap/ag-grid-theme/dist/27-standard.css';
@import '@sg-bootstrap/shepherd/dist/theme-standard.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';

:root {
  --header-margin-top: 74px;
}

.ng-touched.ng-invalid {
  @extend .is-invalid !optional;
}

.sgwt-account-center-environment-tag {
  // background-color: $danger !important;
  background-color: red !important;
}

.modal-dialog-details {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;

  @media (min-width: 576px) {
    width: 576px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.modal-content-details {
  height: 100%;
}

.modal-body-details {
  max-height: 100%;
}

.modal-footer-details {
  position: absolute;
  bottom: 0;
}
.mw-15{
  min-width: 15%!important;
}
//Texte noir dans le ngBoostrap datepicker
.ngb-dp-week>.ngb-dp-day>.btn-light {
  color: #212529 !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.ag-cell.ag-grid-cell-right {
  text-align: right;
}

.ag-cell.ag-grid-cell-centered {
  text-align: center;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1700px;
  }
}

ngb-modal-backdrop {
  z-index: 1000 !important;
}
 
.softLoading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

// .test {
//   background-color: red;
// }

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.softLoading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.softLoading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 3px solid grey;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;


}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
