@import '@sg-bootstrap/core/scss/_functions';
@import '@sg-bootstrap/core/scss/_color-standard';
@import '@sg-bootstrap/core/scss/_variables';
@import 'forms-styles';

.disable-settlement {
  pointer-events: none !important;
  opacity: 0.5;
}

.invalid-fields {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $red-socgen;
}

.section-forms:not(:first-child) {
  margin-top: 3rem;
}

.mandatory {
  color: #dd3724;
}

.title-case {
  display: inline-block;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  & > span {
    display: inline-block;
    font-weight: bold;
    background: transparent;
    animation: spin 2s linear infinite;
  }
  font-size: 3rem;
  &.big {
    font-size: 5rem;
  }
  &.small {
    font-size: 1.5rem;
  }
}
