@import '@sg-bootstrap/core/scss/_functions';
@import '@sg-bootstrap/core/scss/_color-standard';
@import '@sg-bootstrap/core/scss/_variables';

label {
  user-select: none;
  &.required {
    &:after {
      content: " *";
      color: $red-socgen;
    }
  }
}

input.form-control:disabled {
  border: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
  padding-left: 0em;

  &::placeholder {
    color: transparent !important;
    user-select: none;
   }
}

.h-100{
  height: 100% !important;
}

sg-daterangepicker .dp__input {
  width: 8rem
}
